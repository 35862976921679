import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    played: []
  },
  mutations: {
    setPlayedItem(state, timestamp){
      if (!state.played.includes(timestamp)){
        state.played.push(timestamp)
      }
    }
  },
  actions: {
    setPlayedItem({ commit }, value) {
      commit('setPlayedItem', value);
    }
  },
  getters: {
    getPlayed(state) {
      return state.played
    }
  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key: 'RadioPlayer'
    }
  )]
})
