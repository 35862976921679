<template>
  <div class="p-radio-player">
    <div class="p-radio-player__inner">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          rowsPerPageLabel: '表示数',
          nextLabel: '次のページ',
          prevLabel: '前のページ',
          allLabel: '全て',
        }"
        @on-row-click="changeAudio"
      />
      <div class="p-radio-player__title">♪{{ title }}</div>
      <vue-plyr ref="player">
        <audio controls crossorigin playsinline>
          <source
            :src="audiofile"
            type="audio/mp3"
          />
        </audio>
      </vue-plyr>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'

export default {
  name: 'RadioPlayer',
  data() {
    return {
      player: null,
      title: '',
      audiofile: '',
      index: 0,
      columns: [
        {
          label: '',
          field: 'now_playing',
          sortable: false,
          formatFn: this.playingIcon
        },
        {
          label: '放送日',
          field: 'timestamp',
          formatFn: this.timestampFilter,
        },
        {
          label: 'タイトル',
          field: 'title'
        },
        {
          label: 'カテゴリー',
          field: 'category'
        },
        {
          label: 'サイズ',
          field: 'filesize',
          formatFn: this.filesizeFilter
        },
        {
          label: 'ステータス',
          field: 'played',
          tdClass: this.statusColor,
          formatFn: this.statusFilter
        },
      ],
      rows: [],
    }
  },
  mounted() {
    this.player = this.$refs.player.player
    this.player.on('ended', () => this.savePlayed())
    axios.get('./api/')
      .then(response => {
        this.rows = response.data.archive
        this.loadPlayed()
      })
      .catch(error => console.log(error))
  },
  methods: {
    changeAudio(params) {
      this.rows[this.index]['now_playing'] = false
      this.title = params.row.title
      this.audiofile = params.row.url
      this.index = params.row.originalIndex
      this.player.source = {
        type: 'audio',
        title: params.row.title,
        sources: [
          {
            src: params.row.url,
            type: 'audio/mp3',
          }
        ],
      }
      this.rows[this.index]['now_playing'] = true
    },
    savePlayed() {
      let index = this.index
      this.rows[index]['played'] = true
      this.$store.dispatch('setPlayedItem', this.rows[index]['timestamp'])
    },
    loadPlayed() {
      let playedList = this.getPlayed
      this.rows.forEach((element, index) => {
        if (playedList.includes(element.timestamp)) {
          this.rows[index]['played'] = true
        }
      })
    },
    playingIcon(value){
      if(value){
        return '♪'
      }else{
        return ''
      }
    },
    timestampFilter(value){
      return dayjs(value * 1000).format('YYYY-MM-DD')
    },
    filesizeFilter(value) {
      let mbsize = value / 1048576
      return mbsize.toFixed(1) + 'MB'
    },
    statusFilter(value) {
      if (value) {
        return '視聴済み'
      }else{
        return '未視聴'
      }
    },
    statusColor(value) {
      if (value.played) {
        return 'is-bg-primary';
      } else {
        return 'is-bg-danger';
      }
    },
  },
  computed: {
    getPlayed() {
      return this.$store.getters.getPlayed
    }
  }
}
</script>
