<template>
  <div id="app">
    <RadioPlayer/>
  </div>
</template>

<script>
import RadioPlayer from './components/RadioPlayer.vue'

export default {
  name: 'App',
  components: {
    RadioPlayer
  }
}
</script>
