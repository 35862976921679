var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-radio-player"},[_c('div',{staticClass:"p-radio-player__inner"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
        enabled: true,
        mode: 'pages',
        rowsPerPageLabel: '表示数',
        nextLabel: '次のページ',
        prevLabel: '前のページ',
        allLabel: '全て',
      }},on:{"on-row-click":_vm.changeAudio}}),_c('div',{staticClass:"p-radio-player__title"},[_vm._v("♪"+_vm._s(_vm.title))]),_c('vue-plyr',{ref:"player"},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"src":_vm.audiofile,"type":"audio/mp3"}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }