import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(VuePlyr)
Vue.use(VueGoodTablePlugin)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
